import { version } from '../package.json'

type ThemeDef = {
  readonly key: 'light' | 'dark' | 'auto'
  readonly icon: string
}

const themes: readonly ThemeDef[] = [
  {
    key: 'auto',
    icon: 'material-symbols:computer-outline',
  },
  {
    key: 'dark',
    icon: 'material-symbols:dark-mode-outline',
  },
  {
    key: 'light',
    icon: 'material-symbols:light-mode-outline',
  },
] as const

export default defineAppConfig({
  version,
  PROMOTION_CHANGE_INTERVAL: 1000 * 60 * 60, // change promo every 60 min
  datePicker: {
    minDate: '1924-07-01',
    maxDate: '2024-12-01',
  },
  dailyWordSubscribeUrl: {
    en: 'https://shop.unityonline.org/subscribe?plan=Digital&PromotionCode=PP346001&GiftPromotionCode=PP346001',
    es: 'https://compra.unityonline.org/subscribe?Plan=Digital&PromotionCode=PP346002&GiftPromotionCode=PP346002',
  },
  notificationTimeout: 5000, // ms | 5sec
  navbar: {
    prayer: {
      icon: 'IconCandle',
      path: '/prayer',
    },
    'daily-word': {
      icon: 'IconDW',
      path: '/daily-word/today',
    },
    playlist: {
      icon: 'IconMeditate',
      path: '/playlist/inspiration',
    },
  },
  sentry: {
    client: {
      tracesSampleRate: 0.2,
      replaysSessionSampleRate: 0.05,
      replaysOnErrorSampleRate: 0.1,
      tracePropagationTargets: ['localhost', 'api.unity.org', 'be.unity.org'],
    },
    server: {
      tracesSampleRate: 0.2,
      profilesSampleRate: 0.5,
    },
  },
  // YouTube playlist IDs.
  youtubePlaylists: {
    inspiration: {
      en: 'PLzI3e0Hg1NV6Afjc9-rnOtg6I_jI6ItJF',
      es: 'PLjP5Z4PFTjeL9379QIb32xHQrPgj7tb6i',
    },
    meditations: 'PLzI3e0Hg1NV5a4p2HEFKOcUxLmrf5Hpce',
  },
  themes,
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    PROMOTION_CHANGE_INTERVAL: number
    datePicker: {
      minDate: string
      maxDate: string
    }
    /** Theme configuration */
    themes?: typeof themes

    youtubePlaylists: Record<
      'inspiration' | 'meditations',
      string | Record<'en' | 'es', string>
    >
  }
}
