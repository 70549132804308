import {
  stringifyParsedURL,
  stringifyQuery,
  isEqual,
  type QueryObject,
} from 'ufo'

type PathInfo = {
  path: string
  query?: QueryObject
}

const isSamePathInfo = (a: PathInfo, b: PathInfo): boolean => {
  const aP = {
    pathname: a.path,
    search: stringifyQuery(a.query || {}),
  }

  const aUrl = stringifyParsedURL({
    pathname: a.path,
    search: stringifyQuery(a.query || {}),
  })

  const bUrl = stringifyParsedURL({
    pathname: b.path,
    search: stringifyQuery(b.query || {}),
  })

  return isEqual(aUrl, bUrl)
}

export default defineNuxtRouteMiddleware((to, from) => {
  const { path, query } = from

  const previousRoute = useState('previous-path', () => ({
    path: '/',
    query: {},
  }))

  const { path: prePath, query: preQuery } = unref(previousRoute)
  const oldPath = {
    path: prePath,
    query: { ...unref(preQuery) },
  }

  const newPath = {
    path: path,
    query: { ...unref(query) },
  }

  const isSamePath = isSamePathInfo(oldPath, newPath)
  const isFocusedLayout = ['blank', 'focused'].includes(
    from?.meta?.layout || 'default',
  )

  if (import.meta.dev) {
    console.log(`Setting previous-path to ${path}`, {
      isSamePath,
      isFocusedLayout,
      isSkipping: isSamePath || isFocusedLayout,
      new: {
        ...newPath,
        layout: from.meta.layout,
      },
      old: oldPath,
      to,
      from,
    })
  }

  if (isSamePath || isFocusedLayout) return

  previousRoute.value = {
    path,
    query,
  }
})
